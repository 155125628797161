<!--历史建设项目详情 xwh 20210910-->
<template>
    <div class="">
        <div class="pro-info-box">
            <table>
                <colgroup>
                    <col width="200" />
                    <col width="300" />
                    <col width="200" />
                    <col width="300" />
                    <col width="200" />
                    <col width="300" />
                </colgroup>
                <tr>
                    <th>项目名称</th>
                    <td colspan="3">{{ proInfo.projectName }}</td>
                    <td colspan="2" rowspan="8">
                        <baidu-map id="mapContainer" @ready="mapReady" :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT">
                           <bm-marker :position="point" ></bm-marker>
                            <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
                        </baidu-map>
                    </td>
                </tr>
                <tr>
                    <th>建设单位</th>
                    <td colspan="3">{{ proInfo.companyName }}</td>
                </tr>
                <tr>
                    <th>总投资（万元）</th>
                    <td>{{ utils.dataFormat(proInfo.amount) }}</td>
                    <th>项目类别</th>
                    <td>{{ proInfo.projectType }}</td>
                </tr>
                <tr>
                    <th>建设性质</th>
                    <td>{{ proInfo.projectProperty }}</td>
                    <th>工程用途</th>
                    <td>{{ proInfo.projectUsage }}</td>
<!--                    <th>数据等级</th>
                    <td>{{ proInfo.dataLevel }}</td>-->
                </tr>
                <tr>
                    <th>总面积（平米）</th>
                    <td>{{ proInfo.area }}</td>
                    <th>项目编号</th>
                    <td>{{ proInfo.projectNo }}</td>
                </tr>
                <tr>
                    <th>建设用地规划许可证编号</th>
                    <td>{{ proInfo.landPlanningLicenseNo }}</td>
                    <th>建设工程规划许可证编号</th>
                    <td>{{ proInfo.projectPlanningLicenseNo }}</td>
                </tr>
                <tr>
                    <th>行政区划</th>
                    <td>{{ proInfo.province + proInfo.city + proInfo.county}}</td>
                    <th>立项文号</th>
                    <td>{{ proInfo.setupNo }}</td>
                </tr>
                <tr>
                    <th>立项级别</th>
                    <td>{{ proInfo.setupLevel }}</td>
                    <th>立项批复机关</th>
                    <td>{{ proInfo.setupAuthority }}</td>
                </tr>
                <tr>
                    <th>资金来源</th>
                    <td>{{ proInfo.amountFrom }}</td>
                    <th>计划开工</th>
                    <td>{{ proInfo.startDate }}</td>
                    <th>计划竣工</th>
                    <td>{{ proInfo.endDate }}</td>
                </tr>
                <tr>
                    <th>国有资金占比</th>
                    <td>{{ proInfo.countryRate }}</td>
                    <th>建设规模</th>
                    <td>{{ proInfo.constructionScale }}</td>
                    <th>具体地点</th>
                    <td>{{ proInfo.address }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import {getStore} from "@/util/store";
//提前全局声明BMap，map，方便后续方法使用BMap.XX方法
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue'//引入缩放控件
let map = {};
export default {

    props: {
        'proInfo': {},
    },
    data() {
        return {
            point: "",
            proname: getStore('thisProject').proName,//项目名称
            proImg: require('../../assets/img/map_icon_nowlocation.png'),//中心点图形
            center: {//中心点
                lng: '',
                lat: ''
            },
            ZoomNum: 16,//当前缩放比例
            zoom: 16,//初始化缩放比例
        }
    },
    created() {
        //console.log(this.proInfo)
    },
    mounted() {

    },
    methods: {
        mapReady({ BMap, map }) {
            // 选择一个经纬度作为中心点
            console.log(this.proInfo.longitude, this.proInfo.latitude)
            this.point = new BMap.Point(this.proInfo.longitude, this.proInfo.latitude);
            map.centerAndZoom(this.point, 13);
        },
        //加载百度地图api
        /*loadBmapJS() {
            if (window.BMap) {
                return Promise.resolve();
            }
            if (bmapPromise) {
                return bmapPromise;
            }
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = 'https://api.map.baidu.com/api?v=3.0&ak=jMnfAv2MlXEUmzzDjjGvlmXrpj0TP66a&callback=initMap'
            document.body.appendChild(script);
            bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                };
                script.onerror = error => {
                    reject(error)
                }
            })
        },*/
        //初始化地图
        /*initMap() {
            BMap = window.BMap;
            map = new BMap.Map('mapContainer');
            //初始化地图中心、层级
            map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), this.zoom);
            //允许地图鼠标滚轮缩放层级
            map.enableScrollWheelZoom(true);
            //定义右上角百度地图工具栏 平移和缩放按钮
            var top_right_navigation = new BMap.NavigationControl({
                anchor: BMAP_ANCHOR_TOP_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });
            map.addControl(top_right_navigation);
            //监听地图缩放，赋值到ZoomNum
            map.addEventListener("zoomend", (e) => {
                this.ZoomNum = e.target.getZoom()
            });
            // 创建点
            let myIcon = new BMap.Icon(this.proImg, new BMap.Size(32, 32));
            let mpoint = new BMap.Point(this.center.lng, this.center.lat);
            let marker = new BMap.Marker(mpoint, {icon: myIcon});
            map.addOverlay(marker);    //增加点
            marker.addEventListener("click", () => {

            });
            //中心点标签
            let cont = "<span class='markername' style='background-color:rgba(255,255,255,.8);border:1px solid #498AFF'>" + this.proname + "</span><span id='arrows'></span>";
            let label = new BMap.Label(cont, {offset: new BMap.Size(0, -15)});
            marker.setLabel(label);
            marker.disableMassClear(); //不被clearOverlay清除
            this.search('tabTraffic');
        },*/
    }
}
</script>

<style scoped lang="less">
#allmap{
    height: 600px;
    width: 600px;
    margin: 0 auto;
}
.pro-info-box {
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 10px;
        border: 1px solid #EBEBEB;
        white-space: nowrap;
        font-weight: 500;
        text-align: left;
    }

    th {
        background: #F6F6F6;
    }

    td {
        height: 1px;
        white-space: normal;

        #mapContainer {
            height: 100%;
            background: #ccc;
        }
    }

}
</style>