<!--历史建设项目 xwh 20210909-->
<template>
    <div class="base-container">
        <div class="table-wrapper">
             <div class="module-title">历史建设信息</div>
            <div class="search-box">
               <div class="tab_box">
                        <div class="tab-item" v-show="isConstructorProject" :class="{'selected':activeName == 'realestate'}" @click="titleTab('realestate')">建设方项目 {{realestateNum}}</div>
                        <div class="tab-item" v-show="isExecutorProject" :class="{'selected':activeName == 'investor'}" @click="titleTab('investor')">投资方项目 {{investorNum}}</div>
                </div>
                <el-form :inline="true" :model="params" class="demo-form-inline" size="mini">
                    <el-form-item label="项目名称">
                        <el-input v-model="projectName" placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-box">
                <el-table ref="table" :data="tableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                          v-loading="loading" @sort-change="sortChange">
                    <el-table-column sortable='custom' type="index" label="序号" width="80" align="center"></el-table-column>
                    <el-table-column sortable='custom' prop="projectName" label="项目名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column sortable='custom' v-if="activeName == 'investor'" prop="constructCompany" label="建设单位" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="项目属地">
                        <template slot-scope="scope">
                            <span>{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.country }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable='custom' prop="projectType" label="项目类别"></el-table-column>
                    <el-table-column sortable='custom' prop="area" label="总面积(平米)" align="right">
                        <template slot-scope="scope">
                            <span>{{ utils.dataFormat(scope.row.area) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable='custom' prop="amount" label="项目总投资(万元)" align="right">
                        <template slot-scope="scope">
                            <span>{{ utils.dataFormat(scope.row.amount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable='custom' prop="startDate" label="计划开工时间" align="right">
                        <template slot-scope="scope">
                            <span>{{ scope.row.startDate }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable='custom' prop="endDate" label="计划竣工时间" align="right">
                        <template slot-scope="scope">
                            <span>{{ scope.row.endDate }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="detail(scope.$index, scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 50, 100]"
                    :total=total
                    :current-page=pageNum>
                </el-pagination>
            </div>
        </div>
        <el-dialog title="项目详情" :visible.sync="showDetail" @close="closeDialog" width="90%">
            <detail :proInfo="proInfo"></detail>
        </el-dialog>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations, mapState} from "vuex";
import detail from './historyProjectDetail'

export default {
    components: {
        detail
    },
    data() {
        return {
            params: {
                companyName: '',
                type:'realestate',
            },
            pageNum: 1,
            pageSize: 10,
            projectName: '',
            tableHeight:'',
            tableData: [],
            loading: true,
            total: 0,
            showDetail: false,
            proInfo: {},
            dataTotal: ['合计', '', '', '', '', ''],
            activeName: 'realestate',
            realestateNum: 0,
            investorNum: 0,
            field: '',
            order: '',
            isConstructorProject: true,
            isExecutorProject: true
            
        }
    },
    created() {
        
        if(this.utils.isDisplay('executorProject:view')){
            this.isExecutorProject = true;
            this.activeName = 'investor';
        }else{
            this.isExecutorProject = false;
            this.activeName = 'realestate';
        }
        if(this.utils.isDisplay('constructorProject:view')){
            this.isConstructorProject = true;
            this.activeName = 'realestate';
        }else{
            this.isConstructorProject = false;
            this.activeName = 'investor';
        }
    },
    mounted() {
        // this.getTableHeight();
        this.getTableData();
        this.getInvestorNum();
    },
    methods: {
        //获取table-box的高度赋值给el-table
        getTableHeight() {
            var height = document.getElementsByClassName('table-box')[0].offsetHeight;
            //console.log(height)
            this.tableHeight = height;
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'building',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        titleTab(title){
            this.pageNum = 1;
            this.activeName = title;
            this.loading = true;
            this.field = '';
            this.order = '';
            if(title=="investor"){
                this.getReport(2,'history','investor')
            }else{
                this.getReport(2,'history','building')
            }
            this.getTableData();
        },
        search(){
            this.pageNum = 1;
            this.getReport(2,'history','searchBtn')
            this.getTableData();
        },
        //查询talbe数据
        getTableData() {
            console.log(this.activeName)
            this.axios({
                method: "post", url: '/dms/performanceByName',
                 
                data: {
                    companyName: getStore('thisProject').taxpayerName,
                    projectName: this.projectName,
                    type: this.activeName,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    field: this.field,
                    order: this.order,
                },
            }).then((res) => {
                //console.log(res.data.data)
                if(res.data.data.records.length>0){
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                }else{
                    this.tableData = [];
                    this.total = 0;
                }
                if(this.activeName=='realestate'){
                    this.realestateNum = res.data.data.total;
                }else{
                    this.investorNum = res.data.data.total;
                }
                this.loading = false;
                
            }).catch((error) => {
                console.log(error)
            })
        },
        //首次加载获取投资方数字
        getInvestorNum() {
            this.axios({
                method: "post", url: '/dms/performanceByName',
                 
                data: {
                    companyName: getStore('thisProject').taxpayerName,
                    projectName: '',
                    type: 'investor',
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    field: this.field,
                    order: this.order,
                },
            }).then((res) => {
                 this.investorNum = res.data.data.total;
            }).catch((error) => {
                console.log(error)
            })
        },
      
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableData();
        },
        //更改页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getReport(2,'history',val)
            this.getTableData();
        },
        //查看详情
        detail(index, row) {
            this.getReport(2,'history','detailBtn')
            this.proInfo = row;
            this.showDetail = true;
        },
        closeDialog(){
            this.getReport(2,'history','detailClose')
        },
        sortChange({ prop, order }) {
            this.getReport(2,'history',prop+'Sort')
            this.pageNum = 1;
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableData()
        },
    }
}
</script>

<style scoped lang="less">
.module-title {
    font-size: 16px;
    font-weight: bold;
}
.search-box {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EBEDF0;
    margin-top: 16px;
    padding-top: 16px;
}
.table-wrapper {
    // width: 1200px;
    // margin: 0 auto;
    background: #FFFFFF;
    height: 100%;
    display: flex;
    flex-direction: column;
    // padding: 15px;
    box-sizing: border-box;
}

.table-box {
    flex: 1;
    display: flex;
    overflow: hidden;
}
.tab_box{
    width: 500px;
    height: 40px;
    .tab-item{
        float: left;
        margin-right: 24px;
        cursor: pointer;
        font-size: 16px;
    }
    
    .selected {
        color: #185BCE;
    }
}

.page-box {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>